import React, { Component } from "react";
import '../css/main.css'
import NFT from './Contract.json';
import TRADE from './Trade.json'




class Test extends Component {
  
 

constructor(props) {
  super(props)
  this.state = {
    nft_abi: NFT.abi,
    trade_abi: TRADE.abi,
    trade_contract: null,
    trade_address: '0xcE75de0e252Fe1dD462316C1e4481F566c8B292f',
    account: null,
    networkId: null,
    connected: null,
    status: null,
    acceptance: null,
    inventory: {},
    offerItems: {},
    statusMessage:"Not Connected",
    poffer:{},
    pmatic: 0,
    matic: 0,
    pConnection: false, 
    before: null,
    txnStatus: 'No Pending Transactions.',
    inventoryMessage: "",
    partner_stage: "No Current Partner",
    trade_stage: "No Active Offer.",
    pAcceptance: false,
    currentStep: "Add Items and Send Offer",
    nfts: [],
    page:0,
    isFilteredByContract: false,
    filtered: [],
    fetching: false,
    isFilteredByName: false,
  }
}

  render() {

    
  return(
    
    <div >
      <main>
      <header id="header-home">


        <div class="container">
        
            <nav id="navbar">
                <a href="#"><img src="./img/Logo cyan.png" alt="TrademyNFT" width="250" height="60" /> </a>
                <ul id="main-menu">
                    <li><a href="#">Trade</a></li>
                    <li><a href="#">Step by step guide</a></li>
                    <li><a href="#">Smart contact</a></li>
                    <li>
                        <a href="#" class="btn-main">Connect Wallet</a>
                    </li>
                </ul>
            </nav>
            <div class="header-content">
                <h2>Trading progress</h2>
                <div class="step-line gradient-line-a"></div>

                <ul class="stepbar">
                    <li>
                        <div class="step">1</div>
                        <div class="step-title">Connect your web 3 wallet</div> 
                    </li>

                    <li>
                        <div class="step">2</div>
                        <div class="step-title">Choose item(s) you want to trade</div>
                    </li>

                   
                    <li>
                        <div class="step">3</div>
                        <div class="step-title">Enter other party's wallet address </div>
                    </li>
                    <li>
                        <div class="step">4</div>
                        <div class="step-title">Send your offer</div>
                    </li>
                    <li>
                        <div class="step">5</div>
                        <div class="step-title">Wait for other party to respond</div>
                    </li>
                    <li>
                        <div class="step">6</div>
                        <div class="step-title">Approve the trade!</div>
                    </li>
                    <li>
                        <div class="step">7</div>
                        <div class="step-title">3rd party approval</div>
                    </li>
                </ul>
                <div class="step-explanation">
                    <strong>Step 1:</strong> Connect your wallet in order to kick off the trade 
                </div>

            </div>
        </div>

    </header>

    <section id="section">
        <div class="container">
            <div class="panels">
                <div class="panel-available-items">
                    <h3>Your avaliable items</h3>
                    <form action="#">
                        <div class="panel">
                            <div class="panel-header">
                                <div class="search">
                                    <p class="lead">Search by name</p>
                                    <input type="search" name="name" id="name" placeholder="Name"/><a href="#" class="btn-magnifier"><img src="./img/magnifier.png" alt=""/></a>
                                </div>
                                <div class="v-line"></div>
                                <div class="search">
                                    <p class="lead">Search by contract</p>
                                    <input type="search" name="contract" id="contract" placeholder="Contract"/><a href="#" class="btn-magnifier"><img src="./img/magnifier.png" alt=""/></a>
                                </div>
                            </div>
                            <div class="empty">
                                <p class="lead">There are currently no available items. Connect your wallet first.</p>
                                <a href="#" class="btn-main">Connect Wallet</a>
                            </div>
                            <footer class="panel-footer">
                                <a href="#" class="btn-shevron lead"><i class="fas fa-chevron-left"></i>Prev</a> 
                                <a href="#" class="btn-shevron lead">Next<i class="fas fa-chevron-right"></i></a>
                            </footer>
                        </div>
                    </form>
                </div>
                <div class="panel-your-offering">
                    <h3>Your offering</h3>
                    <div class="panel">
                        <div class="empty">
                            <p class="lead">There are currently no available items. Connect your wallet first.</p>
                        </div>
                    </div>
                </div>
                <div class="panel-their-offer">
                    <h3>Their offer</h3>
                    <div class="panel">
                        <div class="empty">
                            <p class="lead">Enter other party's wallet address</p>
                            <form action="#">
                                <input type="text" name="wallet-address" id="wallet-address"/>
                            </form>
                            <div>
                                <a href="#" class="btn-clear small disabled">Clear</a>
                                <a href="#" class="btn-approve small disabled">Done</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-addition">
                    <div class="addition-box">
                        <p>Additional <strong class="text-upp">$matic</strong> in the offer (optional):</p>
                        <form action="#">
                            <input type="text" name="matic" id="matic" placeholder="$MATIC"/>
                            <a href="#" class="btn-clear small disabled">Remove</a>
                            <a href="#" class="btn-approve small disabled">Done</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </main>
    </div>
  );
}
}


export default Test;