import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './css/main.css'
import { Home,  Test } from "./components";


function App() {
  return (
    <div className="App">
      <Router>  
        <Switch>
          <Route path="/" exact component = {() => <Home/>}/>
        
        </Switch>
      </Router>

    </div>
  );
}

export default App;
