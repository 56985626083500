import React, { Component } from "react";

import '../css/main.css';
import Web3 from 'web3';
import NFT from './Contract.json';
import TRADE from './Trade.json'
const stringSimilarity = require('string-similarity')



class Home extends Component {
  
  loadWeb3 = async() => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.send('eth_requestAccounts')
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      this.setState({connected:true})
      this.setState({loading_inventory:true})

      this.setState({step_number:2})
      

      const contract = new web3.eth.Contract(this.state.trade_abi,this.state.trade_address)
      this.setState({trade_contract: contract})
      await this.updateTradeStatus()
      this.setState({inventoryMessage:"Loading inventory..."})
      await this.getOffset()
      this.getItems()
      this.getGasPrice()
      
      
      
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
      const web3 = window.web3
      const accounts = await web3.eth.getAccounts()
      this.setState({account: accounts[0]})
      const networkId = await web3.eth.net.getId()
      this.setState({networkId: networkId})
      if(this.state.networkId !== 137){
        window.alert("You must switch your wallet to the Polygon network to use this app.")
        return
      }
      this.setState({connected:true})
      this.setState({loading_inventory:true})
      this.setState({step_number:2})
    
    

      const contract = new web3.eth.Contract(this.state.trade_abi,this.state.trade_address)
      this.setState({trade_contract: contract})
      await this.updateTradeStatus()
      await this.getOffset()
      this.getItems()
      this.getGasPrice()
      
    }
    else {
      window.alert('Non-Etherem browser detected.  You must not have a cryptocurrency wallet connected to the browser, you should consider trying MetaMask!')}
  }

  getName = async(address) => {
    window.web3 = new Web3(window.ethereum)
    await window.ethereum.send('eth_requestAccounts')
    const web3 = window.web3
    const contract = new web3.eth.Contract(this.state.nft_abi,address)
    const name = contract.methods.name().call()
    return name

  }



  getCurrentOffers = async() => {

      window.web3 = new Web3(window.web3.currentProvider)
      const web3 = window.web3

      const contract = this.state.trade_contract
      
      let length
      try{
      length = await contract.methods.getOfferItemCount(this.state.account).call()}
      catch{
         length = 0
      }

     
      let status
      try{
      status = await contract.methods.offerStatus(this.state.account).call()
      } catch{
        status = false

      }
      let acceptance
     
      try{
      acceptance = await contract.methods.acceptanceStatus(this.state.account).call()
    console.log(acceptance)}
        catch{ 
          acceptance = false
        }

      
      this.setState({status: status})
      console.log('acceptance status: ',acceptance)
      await this.setState({acceptance: acceptance})
      if(status===false){
      } else{
        let offer = {}
        
        for(let i=0;i<length;i++){
          
          let address = await contract.methods.getAddress(this.state.account,i).call()
          let id = await contract.methods.getOfferId(this.state.account,i).call()
          let name = await this.getName(address)
          let keys = Object.keys(offer)
    
          let nft_contract = new web3.eth.Contract(this.state.nft_abi,address)
          let image = await nft_contract.methods.tokenURI(id).call()
          let response 
          while (response === undefined) {
            try{
          response = await fetch(image).then(response=>response.json())
          }catch{}
          }
         

          if(keys.includes(address)){
            offer[address]['ids'].push(id)
            offer[address]['images'].push(response.image)
           
            this.setState({offerItems:offer})
          }else{
            offer[address] = {}
            offer[address]['name'] = name
            offer[address]['ids'] = [id]
            offer[address]['images'] = [response.image]
            
            this.setState({offerItems:offer})
          
          }
          
          }
     
          let matic
          try{
          matic = await contract.methods.getOfferValue(this.state.account).call()
          }catch{
            matic = 0.0
          }
          this.setState({matic: web3.utils.fromWei(matic.toString(),'ether')})
        
        

      }
      
       let partner
       let partnerConnection
       try{
       partner = await contract.methods.getTradePartner(this.state.account).call()
       this.setState({partner:partner})
       partnerConnection = await contract.methods.getTradePartner(partner).call()}
       catch{
         partner = null
         partnerConnection = null
       }
     
       if(partnerConnection===this.state.account){
        this.setState({pConnection: true})
       
        const plength = await contract.methods.getOfferItemCount(partner).call()
        const pAcceptance = await contract.methods.acceptanceStatus(partner).call()
        this.setState({pAcceptance: pAcceptance})
        let poffer = {}
       
        for(let i=0;i<plength;i++){
          let address = await contract.methods.getAddress(partner,i).call()
          let id = await contract.methods.getOfferId(partner,i).call()
          let name = await this.getName(address)
          let keys = Object.keys(poffer)
          
          let nft_contract = new web3.eth.Contract(this.state.nft_abi,address)
          let image = await nft_contract.methods.tokenURI(id).call()
          let response = await fetch(image).then(response=>response.json())
       
          if(keys.includes(address)){
            poffer[address]['ids'].push(id)
            poffer[address]['images'].push(response.image)
            this.setState({poffer:poffer})
          }else{
            poffer[address] = {}
            poffer[address]['name'] = name
            poffer[address]['ids'] = [id]
            poffer[address]['images'] = [response.image]
            this.setState({poffer:poffer})
          }
        
        }
      let pmatic
      try{
      pmatic = await contract.methods.getOfferValue(partner).call()
      } catch{
        pmatic = 0.0
      }
      pmatic = web3.utils.fromWei(pmatic.toString(),'ether')
      this.setState({pmatic:pmatic})
      }
      else{
         
       }

  }
    

  sendOffer = async() => {
     
      if(this.state.connected){
        if(this.state.networkId===137){
      
      const partner = this.state.partner_address
      window.web3 = new Web3(window.web3.currentProvider)
      const web3 = window.web3
      const contract = this.state.trade_contract
      const offer = this.state.offerItems
      const matic = this.state.matic
      const keys = Object.keys(offer)
      let offerAddresses = []
      let offerIds = []
      for(let i=0;i<keys.length;i++){
        let address = keys[i]
        let ids = offer[address]['ids']
        for(let j=0;j<ids.length;j++){
          offerAddresses.push(address)
          offerIds.push(ids[j])
        }
      }
      

      const value = parseFloat(matic) + .05
   
      let txnvalue = web3.utils.toWei(value.toString(),'ether')
      let gasPrice = await this.getGasPrice()
      gasPrice = await web3.utils.toWei(parseInt(gasPrice).toString(),'gwei')
      let gasLimit = await contract.methods.createOffer(partner,offerAddresses,offerIds,web3.utils.toWei(matic.toString(),'ether')).estimateGas({'from':this.state.account,'value':txnvalue})
      //let gasLimit=250000
      console.log('gasLimit',gasLimit)
      console.log('gasPrice',gasPrice)
      this.setState({txnStatus: "Sending Offer..."})
      this.setState({outstandingTxn: true})
      this.setState({loading_step:true})
      try{
      
      let tx = await contract.methods.createOffer(partner,offerAddresses,offerIds,web3.utils.toWei(matic.toString(),'ether')).send({'from':this.state.account,'value':txnvalue,'gasPrice':gasPrice,'gas':parseInt(1.2*gasLimit)})
      console.log(tx)
      this.setState({partner:partner})
      this.setState({loading_step:false})
      this.setState({modal_active:true})
      this.setState({step_number:5})
      this.setState({currentStep:"Waiting for partner"})}
      catch (err) { 
        console.error(err)
        this.setState({loading_step:false})
        this.setState({txnStatus: "No Pending Transactions."})
        this.setState({outstandingTxn: false})
      }
      } else{
        window.alert("You must connect to the Polygon network to use this app.")
        return
      }
    }

      else{
        window.alert("Please connect to your wallet.")
      }
    
  }
  

  acceptOffer = async(event) => {
      
      if(this.state.connected){
        if(this.state.networkId===137){
      event.preventDefault()
      if(this.state.status && this.state.pConnection){
      window.web3 = new Web3(window.web3.currentProvider)
      const web3 = window.web3

      const contract = this.state.trade_contract
     
     
      this.setState({txnStatus: "Accepting Offer..."})
      this.setState({outstandingTxn:true})
      this.setState({loading_step:true})
      let gasPrice = await this.getGasPrice()
      gasPrice = await web3.utils.toWei(parseInt(gasPrice).toString(),'gwei')
     
   
      try{
      const tx = await contract.methods.acceptOffer().send({'from': this.state.account,'gasPrice':gasPrice,'gas':parseInt(1000000)})
      await this.setState({acceptance: true})
      this.setState({step_number:7})
      this.setState({loading_step:false})
      this.setState({modal_active:true})
      }catch{
        this.setState({txnStatus: "No Pending Transactions."})
        this.setState({outstandingTxn: false})
        this.setState({loading_step:false})
      } } else{
        window.alert("You cannot accept an offer until the other party has sent one.")
      }} else{
        window.alert("You must connect to the Polygon network to use this app.")
        return
      }}else{
        window.alert("Please connect to your wallet.")
      }
    
  }

  declineOffer = async(event) => {
      if(this.state.connected){
        if(this.state.networkId===137){
      event.preventDefault()
      const web3 = window.web3
      const contract = this.state.trade_contract
      this.setState({txnStatus:"Cancelling offer..."})
      this.setState({outstandingTxn:true})
     
      let gasPrice = await this.getGasPrice()
      gasPrice = await web3.utils.toWei(parseInt(gasPrice).toString(),'gwei')
      let gasLimit = await contract.methods.clearOffer().estimateGas({'from':this.state.account})
      try{
      this.setState({cancelling:true})
      let tx = await contract.methods.clearOffer().send({'from': this.state.account,'gasPrice':gasPrice,'gas':parseInt(gasLimit*1.2)})
      this.setState({txnStatus:"No Pending Transactions."})
      this.setState({outstandingTxn:false})
      this.setState({cancelling:false})
      this.setState({modal_active:false})
      this.clearOffers()} catch{
        this.setState({txnStatus:"No Pending Transactions."})
        this.setState({outstandingTxn: false})
        this.setState({cancelling:false})
      }
    
    }else{
      window.alert("You must connect to the Polygon network to use this app.")
      return
    }}else{
      window.alert('Please connect to your wallet.')
    }
     
  }


  addMatic = async(event) => {
      event.preventDefault()
     
      let amount = event.target.matic.value
      console.log(amount,'matic')
      this.setState({matic: amount})
      this.setState({done:true})
      this.setState({step_number:3})
      this.setState({})
  }
  

  removeMatic = async(event) =>{
    event.preventDefault()
    this.setState({matic:0})
    this.setState({done:false})
  }

  getOffset = async() =>{
    //let address = "0x9381CAA558F19a821A2360C59FdF3dB60C09ff52"
    let address = this.state.account
    //let url = "https://api.covalenthq.com/v1/137/address/" + address + "/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=false&key=ckey_747778ea1c2e4446bd9f4b14b3a"
    const page = this.state.page
    let offset = page*10
    let url = "https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=polygon&format=decimal&offset=" +offset.toString() + "&limit=100"
    let items
    this.setState({fetching:true})
    
    let response 
    while (response === undefined) {
      try{
    console.log('fetching')
    response = await fetch(url,{method: "GET", headers: {"X-API-Key": "DtCOolrLBd8T7QUYf3O9Qp1uuYqBRsUqao2TxhrguXYufTlf1SWQD3Tn0eh2AplT"}}).then(response=>response.json())
    }catch{
      window.alert("The server had trouble populating your inventory...you might have too many NFTs")
    }
    }

    items = response['result']
    let i=0
    try{
    while(items[i]['contract_type']!=='ERC721'){
      i=i+1
    }
    this.setState({offset:i})}
    catch{}
  } 
  
  getItems = async() => {
      console.log("updating items")
      let address = this.state.account
      //let address = "0x9381CAA558F19a821A2360C59FdF3dB60C09ff52"
      //let url = "https://api.covalenthq.com/v1/137/address/" + address + "/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=false&key=ckey_747778ea1c2e4446bd9f4b14b3a"
      const page = this.state.page
     

      let offset = page*10 + this.state.offset
      let url = "https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=polygon&format=decimal&offset=" +offset.toString() + "&limit=10"
      let items
      this.setState({fetching:true})
      
      let response 
      while (response === undefined) {
        try{
      console.log('fetching')
      response = await fetch(url,{method: "GET", headers: {"X-API-Key": "DtCOolrLBd8T7QUYf3O9Qp1uuYqBRsUqao2TxhrguXYufTlf1SWQD3Tn0eh2AplT"}}).then(response=>response.json())
      }catch{
        window.alert("The server had trouble populating your inventory...you might have too many NFTs")
      }
      }

      items = response['result']
      console.log(items.length)
    
      console.log("fetched")
      this.setState({fetching:false})
      let NFTs = []
      for(let i=0;i<items.length;i++){
         if(items[i] && items[i]['contract_type']==='ERC721' && NFTs.length<10){
         address = items[i]['token_address']
        
         let nft = {}
         nft['address'] = address
         let image_url=items[i]['token_uri']
         console.log(image_url)
         let response = await fetch(image_url).then(response=>response.json())
         let image = response['image']

         nft['image'] = image
        
         nft['id']= items[i]['token_id']
         nft['name']=items[i]['name']
         NFTs.push(nft)
     
       }else{console.log('not erc721')}
       await this.setState({nfts:NFTs})
      }
      console.log(NFTs.length)
      this.getInventory()
  }
     
  getInventory = async() => {
       this.setState({loading_inventory:true})
       this.setState({inventoryMessage:"Loading Inventory..."})
       const nfts = this.state.nfts
       let inventory = {}
       console.log('inventory populating')
       for(let i=0;i<nfts.length;i++){
            console.log(i)
            let address = nfts[i]['address']
            let image = nfts[i]['image']
            let id = nfts[i]['id']
            let keys = Object.keys(inventory)
            if(keys.includes(address)){}else{
              inventory[address] = {}
              inventory[address]['ids']=[]
              inventory[address]['images']=[]
              inventory[address]['buttons']=[]
              
            }
            inventory[address]['ids'].push(id)
            inventory[address]['images'].push(image)
            inventory[address]['name'] = nfts[i]['name']
            inventory[address]['buttons'].push('btn-main small')
            console.log('added item')
        }
        await this.setState({inventory:inventory})
        this.setState({loading_inventory:false})
        console.log(inventory)
     
  }
      
  
  getGasPrice = async() => {
   let  url = "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=pendingpooltxgweidata"
   const response = await fetch(url,{method:'GET'}).then(response=>response.json())
  
   const price = response['result']['rapidgaspricegwei']
   return price
   
  }

   getImage = async(address) => {
    window.web3 = new Web3(window.ethereum)
    await window.ethereum.send('eth_requestAccounts')
    const web3 = window.web3
    const contract = new web3.eth.Contract(this.state.nft_abi,address)

   }

   offerItem = async(address,id,image,name) => {
     if(this.state.networkId!==137){
       window.alert("You must be connected to the Polygon network to use this app.")
       return
     }else {}
      
      

      const inventory = this.state.inventory
      const nft = inventory[address]
      const buttons = nft['buttons']
      const ids = nft['ids']
      let index = ids.indexOf(id)
      if(buttons[index]==='btn-main small disabled'){
        return
      }

      window.web3 = new Web3(window.ethereum)
      
      await window.ethereum.send('eth_requestAccounts')
      const web3 = window.web3
      const contract = new web3.eth.Contract(this.state.nft_abi,address)
      const owner = await contract.methods.ownerOf(id).call()

      if(this.state.account===owner){
      let approved = await contract.methods.getApproved(id).call()
      if(approved!==this.state.trade_address){
        try{
  
        this.setState({txnStatus: "Approving Item for Contract..."})
        this.setState({outstandingTxn: true})
        this.setState({loading_step:true})


        let inventory = this.state.inventory

        
       
        let index = inventory[address]['ids'].indexOf(id)
        inventory[address]['buttons'][index] = 'loading'
     
        this.setState({inventory:inventory})
        let gasPrice = await this.getGasPrice()
        gasPrice = await web3.utils.toWei(parseInt(gasPrice).toString(),'gwei')
        let gasLimit = await contract.methods.approve(this.state.trade_address,id).estimateGas({'from':this.state.account})
    

        let tx = await contract.methods.approve(this.state.trade_address,id).send({'from':this.state.account,'gasPrice':gasPrice,'gas':parseInt(1.2*gasLimit)})
        while(approved!==this.state.trade_address){
           approved = await contract.methods.getApproved(id).call()
          setTimeout(()=>{},5000)
        }
        this.setState({loading_step:false})
        this.setState({outstandingTxn: false})
        console.log(tx)

        this.setState({txnStatus:"No Pending Transactions."})} catch{
          this.setState({loading_step:false})
          this.setState({outstandingTxn: false})
          let inventory = this.state.inventory        
          let index = inventory[address]['ids'].indexOf(id)
          inventory[address]['buttons'][index] = 'btn-main small'
          
         
          this.setState({inventory:inventory})
          console.log("changed button")
          this.setState({txnStatus:"No Pending Transactions."})
          return
        }
      }
      
      
      let offerItems = this.state.offerItems
      let tempkeys = Object.keys(offerItems)
      if(tempkeys.includes(address)){
        offerItems[address]['ids'].push(id)
        offerItems[address]['images'].push(image)
        let index = inventory[address]['ids'].indexOf(id)
        inventory[address]['buttons'][index] = 'btn-main small disabled'
      }
      else{
        offerItems[address] = {}
        offerItems[address]['name'] = name
        offerItems[address]['images'] = [image]
        offerItems[address]['ids'] = [id]
        let index = inventory[address]['ids'].indexOf(id)
        inventory[address]['buttons'][index] = 'btn-main small disabled'
      }
      this.setState({offerItems:offerItems})
      this.setState({inventory:inventory})
      
     
    }else{window.alert("You don't own that NFT")}
    
    
   }
   clearOffers = async() => {

      const offer = {}
      await this.setState({offerItems:offer,poffer:offer})
      this.getInventory()
      this.setState({done:false})
      
      if(this.state.connected){
        this.setState({step_number:2})
      }
   }

   removeItem = async(address,id) => {
        let offerItems = this.state.offerItems
        let inventory = this.state.inventory
        let index = offerItems[address]['ids'].indexOf(id)
        if(index!== -1 ){
          offerItems[address]['images'].splice(index,1)
          offerItems[address]['ids'].splice(index,1)
        }
        index = inventory[address]['ids'].indexOf(id)
        inventory[address]['buttons'][index] = 'btn-main small'
        this.setState({offerItems:offerItems})
        this.setState({inventory:inventory})
      
   }

   refreshInventory = async() => {
      
      if(this.state.isFilteredByContract){
        await this.setState({isFilteredByContract:false})
        await this.setState({page:0})
        await this.getItems()
        
      }else if(this.state.isFilteredByName){
        await this.setState({isFilteredByName:false})
        this.setState({filtered:[]})
        await this.setState({page:0})
        await this.getItems()

      }
      else{
      await this.getItems()
      }
   }

   searchNFTs = async(event) => {
      event.preventDefault()
      const address = event.target.address.value.toLowerCase()
      const nfts = this.state.nfts
      let filtered = [] 
      for(let i=0;i<nfts.length;i++){
        if(nfts[i]['address'] === address){
          filtered.push(nfts[i])
        }
      }
      console.log(filtered)
      await this.setState({filtered: filtered})
      await this.setState({page:0})
      await this.setState({isFilteredByContract: true})
      this.getInventory()
   }

   searchContract = async()=>{
      
      const token_address = this.state.search_contract

      let page = 0
      this.setState({page:page})
      let offset = page*10             
      let url = "https://deep-index.moralis.io/api/v2/" + this.state.account + "/nft/" + token_address + "?chain=polygon&format=decimal&offset=" +offset.toString()+"&limit=10"
      let response = await fetch(url,{method: "GET", headers: {"X-API-Key": "DtCOolrLBd8T7QUYf3O9Qp1uuYqBRsUqao2TxhrguXYufTlf1SWQD3Tn0eh2AplT"}}).then(response=>response.json())
      let items = response['result']
      console.log(items)
      let NFTs = []
      for(let i=0;i<items.length;i++){
         if(items[i]['contract_type']==='ERC721'){
         let address = items[i]['token_address']
        
         let nft = {}
         nft['address'] = address
         let image_url=items[i]['token_uri']
         let response = await fetch(image_url).then(response=>response.json())
         let image = response['image']
         nft['image'] = image
         nft['id']= items[i]['token_id']
         nft['name']=items[i]['name']
         NFTs.push(nft)
       }else{}
   }
   await this.setState({nfts:NFTs})
   await this.setState({isFilteredByContract: true})
   await this.setState({filterAddress:token_address})
   this.getInventory()
  }

  pageContracts = async()=>{
    const token_address = this.state.filterAddress
    const page = this.state.page
    let offset = page*5              
    let url = "https://deep-index.moralis.io/api/v2/" + this.state.account + "/nft/" + token_address + "?chain=polygon&format=decimal&offset=" +offset.toString()+"&limit=10"
    let response = await fetch(url,{method: "GET", headers: {"X-API-Key": "DtCOolrLBd8T7QUYf3O9Qp1uuYqBRsUqao2TxhrguXYufTlf1SWQD3Tn0eh2AplT"}}).then(response=>response.json())
    let items = response['result']
    let NFTs = []
    for(let i=0;i<items.length;i++){
       if(items[i]['contract_type']==='ERC721'){
       let address = items[i]['token_address']
      
       let nft = {}
       nft['address'] = address
       let image_url=items[i]['token_uri']
       let response = await fetch(image_url).then(response=>response.json())
       let image = response['image']
       nft['image'] = image
       nft['id']= items[i]['token_id']
       nft['name']=items[i]['name']
       NFTs.push(nft)
     }else{}
 }
     await this.setState({nfts:NFTs})
     await this.setState({isFilteredByContract: true})
     this.getInventory()
}


   updateTradeStatus = async()=>{
      await this.getCurrentOffers()
      const contract = this.state.trade_contract
      const status = this.state.status
      const acceptance = this.state.acceptance
      const pstatus = this.state.pstatus
      const pacceptance = this.state.pAcceptance

      await this.setState({status:status,acceptanceStatus:acceptance,pstatus:pstatus,pAcceptance:pacceptance})
      let trade_stage  = this.state.trade_stage

      let partner_stage = this.state.partner_stage
    
      if(this.state.connected && this.state.status && this.state.pConnection && this.state.pAcceptance){
        trade_stage = "Accept to Finalize"
        partner_stage = "Accepted Offer"
        this.setState({modal_active:true})
        this.setState({step_number:6})
      }

      if(this.state.connected&& this.state.status && this.state.pConnection && !this.state.pAcceptance){

        trade_stage = "Connected to Partner"
        partner_stage = "Not Accepted"
        this.setState({currentStep: "Accept offers"})
        this.setState({step_number:6})
        this.setState({modal_active:false})
      }

      if(this.state.connected&& this.state.status && !this.state.pConnection && !this.state.pAcceptance){
        trade_stage="Trade offer sent to "+ this.state.partner
        partner_stage="Waiting for partner..."
        this.setState({currentStep: "Waiting for partner..."})
        this.setState({currentStep: "Accept offers"})
        this.setState({step_number:5})
        this.setState({modal_active:true})

      }

      if(this.state.connected && !this.state.status && !this.state.pConnection){
        trade_stage = "No Current Offer"
        partner_stage = "No Current Partner"
      }
    
      if(this.state.acceptance){
        
        trade_stage="You Have Accepted"
        partner_stage = "Accept to Finalize"
        this.setState({currentStep: "Waiting for partner to accept"})
        this.setState({modal_active:true})
        this.setState({step_number:7})

      }

      if(this.state.connected && !this.state.status){
        trade_stage = "No Current Offer"
        partner_stage = "No Current Partner"
        this.setState({currentStep: "Add Items and Send Offer"})
        
      }
      
      this.setState({trade_stage:trade_stage,partner_stage:partner_stage})
    
   }
    
    lastPage = async()=>{
      let page = this.state.page
      if(page===0){
      }else{
        this.setState({loading_inventory:true})
        this.setState({inventory:{}})
        await this.setState({page: page-1})
        if(this.state.isFilteredByContract){
          await this.pageContracts()
        }else if(this.state.isFilteredByName){
          await this.filterByName()
        }else{
        await this.getItems()}
      }
    }
    nextPage = async()=>{
      this.setState({loading_inventory:true})
      this.setState({inventory:{}})
      let page = this.state.page  + 1
      await this.setState({page:page})
      if(this.state.isFilteredByContract){
        await this.pageContracts()
      }else if(this.state.isFilteredByName) {
         this.filterByName()
      }
        else{
      console.log("Page " + page)
      await this.getItems()}
    }

    offerEventCheck = async() =>{
      console.log('checking events')
      const contract = this.state.trade_contract
      const web3 = window.web3
      const block = await web3.eth.getBlockNumber()
      console.log(block)
      let offerEvents = await contract.getPastEvents('OfferCreated', {fromBlock: block-5, toBlock: 'latest'})
      if(offerEvents &&this.state.step_number <6){
        for(let i=0;i<offerEvents.length;i++){
          console.log(offerEvents[i])
          if(offerEvents[i]['returnValues']['sender'] === this.state.account){
            console.log('sent offer event!')
            
            if(!this.state.updating && this.state.step_number<6){
             this.setState({outstandingTxn: true})
             this.setState({txnStatus:"Txn included in block. Waiting for confirmation..."})
             this.setState({updating:true})
             this.setState({loading_step:false})
             this.setState({step_number:5})
             this.setState({modal_active:true})
            setTimeout(()=>{
                          
                            this.setState({step_number:5})
                            
                            this.updateTradeStatus()
                            this.setState({txnStatus:"No Pending Transactions."})
                            this.setState({outstandingTxn:false})
                            this.setState({partner:offerEvents[i]['returnValues']['receiver']})
                            this.setState({updating: false})},40000)
            }
            
          } else if(offerEvents[i]['returnValues']['sender'] == this.state.partner && offerEvents[i]['returnValues']['receiver']==this.state.account){
            if(!this.state.updating){
              this.setState({updating:true})
              this.setState({outstandingTxn: true})
              this.setState({txnStatus:"Partner sending offer. Waiting for confirmation..."})
              
               setTimeout(()=>{
                console.log('connected to partner')
            
                this.setState({step_number:6})
                this.setState({modal_active:!this.state.modal_active})
                this.updateTradeStatus()
                this.setState({txnStatus:"No Pending Transactions."})
                this.setState({outstandingTxn:false})
                this.setState({updating:false})},40000)
                
            }
          }
        }
      }
    }


    eventCheck = async() =>{
      console.log('checking events')
      const contract = this.state.trade_contract
      const web3 = window.web3
      const block = await web3.eth.getBlockNumber()
      console.log(block)
      let offerEvents = await contract.getPastEvents('OfferCreated', {fromBlock: block-5, toBlock: 'latest'})
      let closeEvents = await contract.getPastEvents('OfferClosed', {fromBlock: block-5, toBlock: 'latest'}) 
      let clearEvents = await contract.getPastEvents('OfferCleared', {fromBlock: block-5, toBlock: 'latest'}) 
      let acceptEvents = await contract.getPastEvents('OfferAccepted', {fromBlock: block-5, toBlock: 'latest'}) 

      if(offerEvents &&this.state.step_number <6){
      for(let i=0;i<offerEvents.length;i++){
        console.log(offerEvents[i])
        if(offerEvents[i]['returnValues']['sender'] === this.state.account){
          console.log('sent offer event!')
          
          if(!this.state.updating && this.state.step_number<6){
           this.setState({outstandingTxn: true})
           this.setState({txnStatus:"Txn included in block. Waiting for confirmation..."})
           this.setState({updating:true})
           this.setState({loading_step:false})
           this.setState({step_number:5})
           this.setState({modal_active:true})
          setTimeout(()=>{
                        
                          this.setState({step_number:5})
                          
                          this.updateTradeStatus()
                          this.setState({txnStatus:"No Pending Transactions."})
                          this.setState({outstandingTxn:false})
                          this.setState({partner:offerEvents[i]['returnValues']['receiver']})
                          this.setState({updating: false})},40000)
          }
          
        } else if(offerEvents[i]['returnValues']['sender']=== this.state.partner && offerEvents[i]['returnValues']['receiver']===this.state.account){
          if(!this.state.updating){
            this.setState({updating:true})
            this.setState({outstandingTxn: true})
            this.setState({txnStatus:"Partner sending offer. Waiting for confirmation..."})
            
             setTimeout(()=>{
              console.log('connected to partner')
          
              this.setState({step_number:6})
              this.setState({modal_active:!this.state.modal_active})
              this.updateTradeStatus()
              this.setState({txnStatus:"No Pending Transactions."})
              this.setState({outstandingTxn:false})
              this.setState({updating:false})},40000)
              
          }
        }
      }
    }

      if(closeEvents){
        for(let i=0; i<closeEvents.length;i++){
          if(closeEvents[i]['returnValues']['address1']===this.state.account || closeEvents[i]['returnValues']['address2']===this.state.account){
            console.log('closed update!')
            if(!this.state.updating){
              this.setState({updating:true})
              this.setState({outstandingTxn: true})
              this.setState({txnStatus:"Finalizing trade. Waiting for confirmation..."})
              this.setState({step_number:8})
              this.setState({modal_active:true})
              setTimeout(()=>{
              
                
                this.getItems()
                this.getInventory()
                this.getCurrentOffers()
                this.setState({updating:false})
                this.setState({txnStatus:"No Pending Transactions."})
                this.setState({status:false,acceptance:false,pAcceptance:false,partner:null})
                this.setState({outstandingTxn:false})
                this.clearOffers()
              },40000)}else{}
            }
          }
        }
      

      if(clearEvents){
        for(let i=0; i<clearEvents.length;i++){
          if(clearEvents[i]['returnValues']['sender']==this.state.account){
            if(!this.state.updating){
              this.setState({updating:true})
              this.setState({outstandingTxn: true})
              this.setState({txnStatus:"Txn included in block. Waiting for confirmation..."})
              this.setState({step_number:2})
              this.setState({modal_active:false})
              this.setState({clearing:false})
              this.setState({pConnection:false})
              window.alert("Your offer has been cancelled.  Please wait a moment for the dashboard to update.")
              this.setState({poffer:{}})
              this.clearOffers()
              setTimeout(()=>{
                
                this.clearOffers()
                this.getCurrentOffers()
                this.setState({txnStatus:"No Pending Transactions."})
                this.setState({outstandingTxn:false})
                this.setState({updating:false})
              },40000)
            }else{}
          }else if(clearEvents[i]['returnValues']['sender']==this.state.partner){
            if(!this.state.updating){
              this.setState({updating:true})
              this.setState({outstandingTxn:true})
              this.setState({txnStatus:"Partner cancelling offer.  Waiting for confirmation"})
              setTimeout(()=> {
                window.alert("Your partner has cancelled their offer.  Hopefully they will make a new one.")
                this.setState({step_number:5})
                this.clearOffers()
                this.getCurrentOffers()
                this.setState({pConnection:false})
                this.setState({txnStatus:"No Pending Transactions."})
                this.setState({outstandingTxn:false})
                this.setState({updating:false})
              },40000)
            }else{}
          }
        }
      }

      if(acceptEvents){
        for(let i=0; i<acceptEvents.length;i++){
          if(acceptEvents[i]['returnValues']['address1'] === this.state.account && this.state.step_number<7){
            if(!this.state.updating){
              this.setState({updating:true})
              this.setState({outstandingTxn: true})
              this.setState({txnStatus:"Txn included in block. Waiting for confirmation..."})
              this.setState({step_number:7})
              this.setState({modal_active:true})
              this.setState({acceptance:true})
              this.setState({loading_step:false})
            setTimeout(()=>{
              console.log('Event update')
              this.getCurrentOffers()
              this.updateTradeStatus()
              this.setState({txnStatus:"No Pending Transactions."})
              this.setState({outstandingTxn:false})
              this.setState({updating:false})
            },40000)}else{}
          } else if (acceptEvents[i]['returnValues']['address1']==this.state.partner & this.state.step_number<=6){
            console.log('Partner accepted')
            if(!this.state.updating){
              this.setState({outstandingTxn: true})
              this.setState({txnStatus:"Partner Accepting Trade. Waiting for confirmation..."})
              this.setState({updating:true})
              setTimeout(()=>{
                //this.setState({step_number:6})
                
                this.getCurrentOffers()
                this.updateTradeStatus()
                this.setState({pAcceptance:true})
                this.setState({modal_active:true})
                this.setState({txnStatus:"No Pending Transactions."})
                this.setState({outstandingTxn:false})
                this.setState({updating:false})
              },40000)} else{}
            }
          }
        }
      }

    score_search_match = async(search_string,item) =>{
      const search_set = search_string.toLowerCase()
      const item_set = item.toLowerCase()
      
      

      let score
      if(item_set.includes(search_set)){
        score = 0.99
      }else{
      score = stringSimilarity.compareTwoStrings(search_set,item_set)}
      console.log('score: ', score.toString())
      return score
    }
   searchByName = async() => {
    
     this.setState({page:0})
     const name = this.state.search_name
     let filtered = []
     let url = "https://deep-index.moralis.io/api/v2/" + this.state.account + "/nft?chain=polygon&format=decimal&offset=0&limit=500"
     let response = await fetch(url,{method: "GET", headers: {"X-API-Key": "DtCOolrLBd8T7QUYf3O9Qp1uuYqBRsUqao2TxhrguXYufTlf1SWQD3Tn0eh2AplT"}}).then(response=>response.json())
     let items = response['result']

     let sorting_list = []
     for(let i=0; i<items.length;i++){
       let score = await this.score_search_match(name,items[i]['name'].toString())
       let item = {}
       item['item']=items[i]
       item['score']=score
       sorting_list.push(item)
     }
     sorting_list.sort((a,b)=>parseFloat(b['score'])-parseFloat(a['score']))
     for(let i=0; i<sorting_list.length;i++){
       if(sorting_list[i]['item']['contract_type']==='ERC721'){
       filtered.push(sorting_list[i]['item'])}
     }
     await this.setState({filtered:filtered})
     this.filterByName()
   }  

  changeSearchName = async(event) =>{
    event.preventDefault()
    const name = event.target.value
    this.setState({search_name:name})
  }

  changeSearchContract = async(event) =>{
    event.preventDefault()
    const address = event.target.value
    this.setState({search_contract:address})
  }
    filterByName = async() =>{
      let items = this.state.filtered
      let page = this.state.page
      let NFTs = []
      let end
      if(items.length<page*10+10){
        end = items.length
      }else{
        end = page*10+10
      }
      for(let i=0+page*10;i<end;i++){
      let nft = {}
       nft['address'] = items[i]["token_address"]
       let image_url=items[i]['token_uri']
       let response = await fetch(image_url).then(response=>response.json())
       let image = response['image']
       nft['image'] = image
       nft['id']= items[i]['token_id']
       nft['name']=items[i]['name']
       NFTs.push(nft)
      }
      await this.setState({nfts:NFTs})
      await this.setState({isFilteredByName: true})
      this.getInventory()
    }

    confirmAddress = async(event) =>{
      event.preventDefault()
      const address = event.target.address.value
      const w3 = window.web3
      let isAddress = await w3.utils.isAddress(address)
      if(!isAddress){
        window.alert('That is not a valid address')
        return
      }
      this.setState({partner_address:address})
      this.setState({step_number:4})
    }
    clearAddress = () =>{
      this.setState({partner_address:''})
      this.setState({step_number:3})
    }

    handleKeyPress = async(event) =>{
      if(event.key === 'Enter'){
        this.searchByName()
      }
    }

    handleKeyPress2 = async(event) =>{
      if(event.key==='Enter'){
        this.searchContract()
            }
    }

constructor(props) {
  super(props)
  this.state = {
    nft_abi: NFT.abi,
    trade_abi: TRADE.abi,
    trade_contract: null,
    trade_address: '0xcE75de0e252Fe1dD462316C1e4481F566c8B292f',
    account: null,
    networkId: null,
    connected: null,
    status: null,
    acceptance: null,
    inventory: false,
    offerItems: {},
    statusMessage:"Not Connected",
    poffer:{},
    pmatic: 0,
    matic: 0,
    pConnection: false, 
    before: null,
    txnStatus: 'No Pending Transactions.',
    inventoryMessage: "",
    partner_stage: "No Current Partner",
    trade_stage: "No Active Offer.",
    pAcceptance: false,
    currentStep: "Add Items and Send Offer",
    nfts: [],
    page:0,
    isFilteredByContract: false,
    filtered: [],
    fetching: false,
    isFilteredByName: false,
    partner: null,

    done: false,
    loading_inventory:false,
    step_number: 1,
    partner_address: null,
    modal_active: false,
    search_name:"",
    search_contract:"",
    cancelling: false,
    offset: 0,
  }
}
  componentDidMount() {
  this.interval = setInterval(()=>{ 

    if(this.state.connected){

  
  this.eventCheck()
}
    

 
  //****Insert Repeated Action Here */

  },10000);
}

componentWillUnmount() {
  if (this.interval) {
      clearInterval(this.interval);
  }
}

  render() {


    
    let connectionMessage = <a onClick={this.loadWeb3} className="btn-main">Connect Wallet</a>
    if (this.state.account){
        connectionMessage =  <a onClick={this.loadWeb3} className="btn-main"><img src="./img/wallet.png" alt=""/> {this.state.account.slice(0,4)+'......'+this.state.account.slice(this.state.account.length-4,this.state.account.length)}</a>
    }

    let partner_address
    

    let progress_bar =  "step-line gradient-line-a"
    let your_offer_html = <div className="panel"><div className="empty"><p className="lead">There are currently no available items. Connect your wallet first.</p></div></div>
    
    let stepOne = <div className='step'>1</div>
    let stepTwo = <div className='step'>2</div>
    let stepThree = <div className='step'>3</div>
    let stepFour = <div className='step'>4</div>
    let stepFive = <div className='step'>5</div>
    let stepSix = <div className= 'step'>6</div>
    let stepSeven = <div className='step'>7</div>
    
    
    let step_instruction = <div className="step-explanation"> <strong>{"Step 1:"}</strong>{"Connect your wallet to activate the trading dashboard. "}</div>
    let search_bar_input = "lead"
    let inventory_footer_html = <footer className="panel-footer"><a onClick={this.lastPage} className="btn-shevron lead"><i className="fas fa-chevron-left"></i>Previous Page</a> <a onClick={this.nextPage} className="btn-shevron lead">Next Page<i className="fas fa-chevron-right"></i></a></footer>
    let clear_offer_html 
    let matic_approve_button =  <a className="btn-approve small disabled">Done</a>
    let matic_remove_button = <a  className="btn-clear small disabled">Remove</a>
    let matic_form_disabled = true
    let clear_address_button=     <a  className="btn-clear small disabled">Clear</a>
    let confirm_address_button=    <button  className="btn-approve small disabled">Done</button>
    let their_address_disabled = true
    let send_offer_button = <a  className="btn-approve upp disabled">Send offer</a>
    let decline_offer_button = <a className="btn-clear upp disabled">Decline offer</a>




    if (this.state.step_number >= 2){
      stepOne= <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      if(this.state.loading_step){
      stepTwo = <div className="step waiting-icon-light"><img className="small-img" src="./img/waiting.png" alt=""/></div>}
      else{
      stepTwo = <div className="step waiting-icon-light"></div>
      }
      step_instruction = <div className="step-explanation"> <strong>{"Step 2:"}</strong>{" Select NFTs you want to trade. You can also add optional amount of "}<span className="text-upp"><strong>{"$MATIC"}</strong></span>{" to it.  Click 'Done' to proceed."}</div>
      your_offer_html = <div className="panel"><div className="empty"><p className="lead">Add your NFTs to include them in the trade.</p></div></div>
      search_bar_input = ""
      inventory_footer_html =<footer className="panel-footer"><a onClick={this.lastPage} className="btn-shevron"><i className="fas fa-chevron-left"></i>Previous Page</a> <a onClick={this.nextPage} className="btn-shevron">Next Page<i className="fas fa-chevron-right"></i></a></footer>
      clear_offer_html =  <a onClick={this.clearOffers} className="btn-main upp">Clear all</a>
      matic_approve_button =  <button type='submit' className="btn-approve small">Done</button>
      matic_remove_button = <a onClick={this.removeMatic} className="btn-clear small">Remove</a>
      matic_form_disabled = false
      progress_bar = "step-line gradient-line-b"
    }

    if (this.state.step_number>=3){
      clear_address_button  = <a className="btn-clear small diabled">Clear</a>
      confirm_address_button = <button type="submit" form='address-form' className="btn-approve small">Done</button>
      matic_remove_button = <a className="btn-clear small disabled">Remove</a>
      matic_approve_button =  <a  className="btn-approve small disabled">Done</a>
      matic_form_disabled = true
      step_instruction = <div className="step-explanation"> <strong>Step 3:</strong> Enter other party's wallet address. (Choose 'Done' to proceed)</div>
      their_address_disabled = false
      stepTwo = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      stepThree = <div className="step waiting-icon-light"></div>
      progress_bar="step-line gradient-line-c"
    }

    if (this.state.step_number >=4){
      confirm_address_button = <button className="btn-approve small disabled">Done</button>
      their_address_disabled = true
      clear_address_button  = <a className="btn-clear small" onClick={this.clearAddress}>Clear</a>
      stepThree = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      stepFour = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>
      progress_bar="step-line gradient-line-d"
      step_instruction = <div className="step-explanation"> <strong>Step 4:</strong> Check everything and finalize your offer. Click "Send Offer" when you are ready to send your offer.</div>
      if(this.state.loading_step){
      stepFour = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>
      send_offer_button = <a  className="btn-approve upp">Sending...</a>
      clear_offer_html =  <a className="btn-main upp disabled">Clear all</a>
      }else{
      send_offer_button = <a onClick={this.sendOffer} className="btn-approve upp">Send offer</a>
      stepFour = <div className="step waiting-icon-light"></div>
    }
    }

    if (this.state.step_number >= 5){
      stepFive = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>
      stepFour = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      step_instruction = <div className="step-explanation"> <strong>Step 5:</strong> All good on your end. Waiting on other party to send their offer...</div>
      progress_bar="step-line gradient-line-e"
      send_offer_button = <a className="btn-approve upp disabled">Send offer</a>
      clear_address_button  = <a className="btn-clear small disabled" >Clear</a>
      decline_offer_button =  <a onClick={this.declineOffer} className="btn-clear upp">Revoke offer</a>
      clear_offer_html =  <a className="btn-main upp disabled">Clear all</a>
      if(this.state.cancelling){
        decline_offer_button =  <a className="btn-clear upp">Revoking offer...</a>  
      }
    }

    if (this.state.step_number >=6) {
      stepFive = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      progress_bar="step-line gradient-line-f"
      
      decline_offer_button =  <a onClick={this.declineOffer} className="btn-clear upp">Decline offer</a>
      step_instruction = <div className="step-explanation">  <strong>Step 6:</strong> Offer received. Please check if everything is according to agreement.  If so, click "Approve trade".</div>
      if(this.state.loading_step){
        stepSix = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>
        send_offer_button = <a onClick={this.acceptOffer} className="btn-approve upp">Approving...</a>
      }else if(this.state.cancelling){
        decline_offer_button =  <a className="btn-clear upp">Declining offer...</a>
        send_offer_button = <a className="btn-approve upp disabled">Approve Trade</a>
        stepSix = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>
      }
      else{
        send_offer_button = <a onClick={this.acceptOffer} className="btn-approve upp">Approve Trade</a>
        stepSix = <div className="step waiting-icon-light"></div>

      }
    }
    
    if (this.state.step_number >=7) {
      stepSix = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      progress_bar = 'step-line gradient-line-g'
      step_instruction = <div className="step-explanation">  <strong>Step 7:</strong>You've approved the trade.  Waiting on the other party to finalize...</div>
      if(this.state.loading_step){
        send_offer_button = <a  className="btn-approve upp disabled">Approving...</a>
        stepSeven = <div className="step waiting-icon-light"><img className="small-img" src="img/waiting.png" alt=""/></div>

      }else{
        stepSeven = <div className="step waiting-icon-light"></div>
        send_offer_button = <a  className="btn-approve upp disabled">Approve Trade</a>
      }
    }
    if (this.state.step_number ===8){
      
      stepSeven = <div className="step checkmark-icon"><i className="fas fa-check"></i></div>
      progress_bar = 'step-line gradient-line-g'
      step_instruction = <div className="step-explanation">  <strong>Step 8:</strong>Your trade is complete!  Wait a minute and then refresh to start a new trade. </div>

    }

let notification_modal_html
let modal_id = ""
if(this.state.modal_active){
modal_id="showcase"

if(this.state.step_number===5){
  if(this.state.cancelling){
    notification_modal_html =  <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-primary-text"><p>Your offer has been sent. <br/> Waiting for the other party to make an offer...</p></div> <div className="card-button"><a className="btn-clear upp">Cancelling offer...</a></div></div></div></div>

  }else{
  notification_modal_html =  <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-primary-text"><p>Your offer has been sent. <br/> Waiting for the other party to make an offer...</p></div> <div className="card-button"><a onClick={this.declineOffer} className="btn-clear upp">Cancel offer</a></div></div></div></div>
  }
} else if(this.state.step_number===6 && this.state.pAcceptance){
  let partner_address = this.state.partner.slice(0,4)+'...'+this.state.partner.slice(this.state.partner.length-4,this.state.partner.length)

  if(this.state.cancelling){
    notification_modal_html = <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-secondary-text"><p><a>{partner_address}</a> approved the trade.</p></div><div className="card-primary-text"><p>Finalize the trade?</p></div><div className="card-button"><a className="btn-clear upp">Declining trade...</a><a className="btn-approve upp disabled">Finalize trade</a></div></div></div></div>
  }else if(this.state.loading_step){
    notification_modal_html = <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-secondary-text"><p><a>{partner_address}</a> approved the trade.</p></div><div className="card-primary-text"><p>Finalize the trade?</p></div><div className="card-button"><a className="btn-clear upp disabled">Decline trade</a><a className="btn-approve upp">Finalizing trade...</a></div></div></div></div>
  }
  else{
  notification_modal_html = <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-secondary-text"><p><a>{partner_address}</a> approved the trade.</p></div><div className="card-primary-text"><p>Finalize the trade?</p></div><div className="card-button"><a onClick={this.declineOffer} className="btn-clear upp">Decline trade</a><a onClick={this.acceptOffer} className="btn-approve upp">Finalize trade</a></div></div></div></div>
  }
}
else if(this.state.step_number===7 && this.state.acceptance){
  if(this.state.cancelling){
    notification_modal_html =  <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-secondary-text"><p>You approved the trade</p></div><div className="card-primary-text"><p>Waiting for other party to respond</p></div><div className="card-button"><a className="btn-clear upp">Cancelling offer...</a></div></div></div></div>

  }else{
  notification_modal_html =  <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/sand-watch.png" alt=""/></div><div className="card-secondary-text"><p>You approved the trade</p></div><div className="card-primary-text"><p>Waiting for other party to respond</p></div><div className="card-button"><a onClick={this.declineOffer} className="btn-clear upp">Cancel offer</a></div></div></div></div>
  }
}
else if(this.state.step_number===8){
notification_modal_html = <div id="finish-card"><div className="card-exit"><a onClick={()=>{this.setState({modal_active:!this.state.modal_active}); this.clearOffers(); this.setState({partner:null})}}><img src="./img/exit.png" alt=""/></a></div><div className="card-container"><div className="card-content"><div><img src="./img/cup.png" alt=""/></div><div className="card-primary-text"><p>Congratulations!</p></div><div className="card-secondary-text"><p> Your trade is complete. Check your wallet for new NFTs!</p></div><div className="card-button"><a onClick={()=>{this.clearOffers(); this.setState({modal_active:false}); this.setState({partner:null})}} className="btn-approve upp">Start another trade</a></div></div></div></div>
}

}
   
    
let their_offer_html = <div className="empty"><p className="lead">Enter other party's wallet address</p><form id='address-form' onSubmit={this.confirmAddress}><input disabled={their_address_disabled} placeholder={this.state.partner_address} type="text" name="address" id="address"/></form><div>{clear_address_button}{confirm_address_button}</div></div>


let inventory = this.state.inventory
const invKeys = Object.keys(inventory)
let inventory_html
if(!this.state.inventory && !this.state.loading_inventory){
  inventory_html = <div className="empty">
  <p className="lead">There are currently no available items. Connect your wallet first.</p>
  <a onClick={this.loadWeb3} className="btn-main">Connect Wallet</a>
</div>}

else if(this.state.loading_inventory){
  inventory_html = <div className="empty">
  <p className="lead">Loading Inventory...</p><br/>
  <a className="btn-main disabled">Connect Wallet</a>
</div>

}

  
const offer = this.state.offerItems
const offerKeys = Object.keys(offer)
let offerHtml = []
  for(let i=0;i<offerKeys.length;i++){
  your_offer_html = []
  let offerItem = offer[offerKeys[i]]
  let offerName = offerItem['name']
  let offerImages = offerItem['images']
  let offerIds = offerItem['ids']

  for(let j=0;j<offerIds.length;j++){
   
    let url = "https://polygonscan.com/address/" + offerKeys[i]
      
    let id
    if(offerIds[j].toString().length>20){
      id = offerIds[j].toString().slice(0,20)+'...'
    }else{
      id = offerIds[j].toString()
    }
    try{
      let offer_item_html
    if(this.state.step_number>=3){
      
      offer_item_html = <li><div><img  className="img-border" height="100" width="100" src={offerImages[j].toString()} crossOrigin='anonymous' alt=""/></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{offerName}</a><p>{id}</p></div><div><a className="btn-clear small disabled">Remove</a></div></li>

    }else{
    offer_item_html = <li><div><img  className="img-border" height="100" width="100" src={offerImages[j].toString()} crossOrigin='anonymous' alt=""/></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{offerName}</a><p>{id}</p></div><div><a onClick={()=>{this.removeItem(offerKeys[i],offerIds[j])}} className="btn-clear small">Remove</a></div></li>
    }
    your_offer_html.push(offer_item_html)}catch{}
  }
  
}
if(offerKeys.length>0){
your_offer_html = <div className="panel"><div className="panel-items"><ul>{your_offer_html}</ul></div></div>
}
  if(invKeys.length>0){
  inventory_html = []}
  for(let i=0;i<invKeys.length;i++){
    let item = inventory[invKeys[i]]
    let name = item['name']
    let images = item['images']
    let ids = item['ids']
    let buttons = item['buttons']
    let item_html
    for(let j=0;j<ids.length;j++){
      let id
      let url = "https://polygonscan.com/address/" + invKeys[i]

      if(ids[j].toString().length>20){
        id = ids[j].toString().slice(0,20)+'...'
      }
      else{
        id=ids[j].toString()
      }
      try{
      if(this.state.step_number>2){
        item_html = <li><div><img className="img-border" height="50" width = "50" src={images[j].toString()} crossOrigin='anonymous' placeholder={name} alt=""></img></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{name}</a><p>{id}</p></div><div><a className="btn-main small disabled">Add</a></div></li>
      }else{
        if(this.state.loading_step && buttons[j]==='loading'){
          item_html = <li><div><img className="img-border" height="50" width = "50" src={images[j].toString()} crossOrigin='anonymous' placeholder={name} alt=""></img></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{name}</a><p>{id}</p></div><div className='waiting-icon-dark'><img src="../img/waiting-dark.png"/></div></li>
        }else{
      item_html = <li><div><img className="img-border" height="50" width = "50" src={images[j].toString()} crossOrigin='anonymous' placeholder={name} alt=""></img></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{name}</a><p>{id}</p></div><div><a onClick={()=>{this.offerItem(invKeys[i],ids[j],images[j],name)}} className={buttons[j]}>Add</a></div></li>}
      } 
      inventory_html.push(item_html)
      }catch{console.log("ERROR")}
      }
  }

  if(this.state.inventory && !this.state.loading_inventory){
    inventory_html = <div className="panel-items"><ul>{inventory_html}</ul></div>}
    inventory_html = [inventory_html,inventory_footer_html]
  
  const poffer = this.state.poffer
  const pofferKeys = Object.keys(poffer)
  let pofferHtml = [<li><div className="nft-items"><strong>$MATIC </strong> Offered: {this.state.pmatic}</div></li>]

  for(let i=0;i<pofferKeys.length;i++){
    
    let pofferItem = poffer[pofferKeys[i]]
    let pofferName = pofferItem['name']
    let pofferImages = pofferItem['images']
    let pofferIds = pofferItem['ids']
  
    for(let j=0;j<pofferIds.length;j++){
      let item_html
      let url = "https://polygonscan.com/address/" + pofferKeys[i]

      let id
      if(pofferIds[j].toString().length>20){
        id = pofferIds[j].toString().slice(0,20)+'...'
      }
      else{
        id=pofferIds[j].toString()
      }
      try{
        item_html = <li><div><img className="img-border" height="50" width = "50" src={pofferImages[j].toString()} crossOrigin='anonymous' placeholder={pofferName} alt=""></img></div><div className="nft-items"><a title="Click to view on Polygonscan" href={url} target="_blank">{pofferName}</a><p>{id}</p></div></li>
      }catch{}
      pofferHtml.push(item_html)
    } 
  }
  if(this.state.pConnection){
    their_offer_html = <div className='panel-items'>{pofferHtml}</div>
    if(this.state.partner){
      partner_address = ' ( ' + this.state.partner.slice(0,4)+'...'+this.state.partner.slice(this.state.partner.length-4,this.state.partner.length)+ ' ) '
    }
  }
  let contract_search_clear
  let name_search_clear
  if(this.state.isFilteredByContract){
    contract_search_clear = <a className="btn-clear small" onClick={this.refreshInventory}>Clear Search</a>
  }
  if(this.state.isFilteredByName){
    name_search_clear = <a className="btn-clear small" onClick={this.refreshInventory}>Clear Search</a>
  }

  return(
    


    <div >
      {notification_modal_html}
      <main id={modal_id}>
      <header id="header-home">

     
        <div className="container">
        
            <nav id="navbar">
                <a ><img src="./img/Logo cyan.png" alt="TrademyNFT" width="250" height="60" /> </a>
                <ul id="main-menu">
                  
                    <li><a href="https://polygonscan.com/address/0xce75de0e252fe1dd462316c1e4481f566c8b292f" target="_blank">View Smart contact</a></li>
                    <li>
                        {connectionMessage}
                    </li>
                </ul>
            </nav>
            <div className="header-content">
                <h2>Trading progress</h2>
                <div className={progress_bar}></div>

                <ul className="stepbar">
                    <li>
                        {stepOne}
                        <div className="step-title">Connect your web 3 wallet</div> 
                    </li>

                    <li>
                        {stepTwo}
                        <div className="step-title">Choose item(s) you want to trade</div>
                    </li>

                   
                    <li>
                        {stepThree}
                        <div className="step-title">Enter other party's wallet address </div>
                    </li>
                    <li>
                        {stepFour}
                        <div className="step-title">Send your offer</div>
                    </li>
                    <li>
                        {stepFive}
                        <div className="step-title">Wait for other party to respond</div>
                    </li>
                    <li>
                        {stepSix}
                        <div className="step-title">Trade Approval</div>
                    </li>
                    <li>
                        {stepSeven}
                        <div className="step-title">Finalize the Trade!</div>
                    </li>
                </ul>
                
                    {step_instruction}
                

            </div>
        </div>

    </header>

    <section id="section">
        <div className="container">
            <div className="panels">
                <div className="panel-available-items">
                    <h3>Your available items</h3>
                    <form action="#">
                        <div className="panel">
                            <div className="panel-header">
                                <div className="search">
                                    <p className={search_bar_input}>Search by name  {name_search_clear}</p>
                                    <input type="search" onChange={this.changeSearchName} onKeyPress={this.handleKeyPress} name="name" id="name" placeholder="Name"/><a onClick={this.searchByName} className="btn-magnifier"><img src="./img/magnifier.png" alt=""/></a>
                                </div>
                                <div className="v-line"></div>
                                <div className="search">
                                    <p className={search_bar_input}>Search by contract {contract_search_clear}</p>
                                    <input onKeyPress={this.handleKeyPress2} onChange={this.changeSearchContract} type="search" name="contract" id="contract" placeholder="Contract"/><a onClick={this.searchContract} className="btn-magnifier"><img src="./img/magnifier.png" alt=""/></a>
                                </div>
                            </div>

                            {inventory_html}

                            

                        </div>
                    </form>
                </div>
                <div className="panel-your-offering">
                    <h3>Your offer</h3>

                   

                    {your_offer_html}

                    

                    

                </div>
                <div className="panel-their-offer">
                    <h3>Their offer {partner_address}</h3>
                    <div className="panel">
                        {their_offer_html}
                    </div>
                </div>
                <div className="panel-addition">

                    <div className="addition-box">
                        <p>Additional <strong className="text-upp">$matic</strong> in the offer (optional):  &nbsp; &nbsp; {this.state.matic}</p>
                        <form id='addMatic' onSubmit={this.addMatic} >
                            <input type="text" name="matic" id="matic" disabled={matic_form_disabled} placeholder="$MATIC" defaultValue={0}/>
                            {matic_remove_button}
                            {matic_approve_button}
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <div className="container">
        <footer id="main-footer">
            <div>
                {clear_offer_html}
                {decline_offer_button}
                {send_offer_button}
                
            </div>
        </footer>
    </div>
    
    </main>
    </div>
  );
}
}


export default Home;
